<template>
  <div
    style="height: 70vh;padding: 5px;gap:16px; overflow: scroll;"
    class="elevation-2 d-flex justify-space-around align-center"
  >
    <!-- Dialog para salvar a meta -->
    <v-dialog v-model="dialogSalvarMeta" persistent width="20vw">
      <v-card>
        <v-card-title>Gravação de meta</v-card-title>
        <v-card-text>
          <p>Deseja realmente gravar a meta para o trimestre?</p>
        </v-card-text>
        <v-card-actions class="d-flex flex-row">
          <v-btn class="error" @click="dialogSalvarMeta = false" x-small
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="salvaMeta" x-small>Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog para Remover a meta -->
    <v-dialog v-model="dialogRemoveMeta" persistent width="20vw">
      <v-card>
        <v-card-title>Exclusão de meta</v-card-title>
        <v-card-text>
          <p>Deseja realmente remover a meta para o trimestre?</p>
        </v-card-text>
        <v-card-actions class="d-flex flex-row">
          <v-btn class="error" @click="dialogRemoveMeta = false" x-small
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="removeMeta" x-small>Remover</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Tabela de Fauramento-->
    <div>
      <v-data-table
        :headers="headersFaturamento"
        dense
        :items="tabFaturamento"
        :items-per-page="tabFaturamento.length"
        class="elevation-1 tbmetasOpimed"
        no-data-text="Sem dados..."
        fixed-header
        hide-default-footer
        ><template v-slot:top>
          <div class="d-flex justify-center aling-center title">
            Faturamento Prospector
          </div>
        </template>
        <template v-slot:[`body.append`]="{ headers }">
          <tr class="blue--text text--darken-3">
            <th
              v-for="(item, index) in headers"
              :key="index"
              v-bind:class="[item.align1]"
            >
              <span class="caption" v-if="item.text === 'Mês'">Total</span>
              <span class="d-flex justify-end">{{ sumField(item.text) }}</span>
            </th>
          </tr>
          <tr class="blue--text text--darken-3">
            <th
              v-for="(item, index) in headers"
              :key="index"
              v-bind:class="[item.align1]"
            >
              <span class="caption" v-if="item.text === 'Mês'">Média</span>
              <span class="d-flex justify-end">{{
                mediaField(item.text)
              }}</span>
            </th>
          </tr>
        </template></v-data-table
      >
    </div>

    <!-- Espelho de Cálculo -->
    <div class="d-flex flex-column align-center" style="width: 25vw;">
      <span class="title">Cálculo da Meta</span>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="indRejuste"
            dense
            hide-details
            label="Índice de reajuste"
            outlined
            @blur="calculaFormula()"
            :readonly="travaCampos"
            :class="travaCampos ? 'inputCalcEspelho' : ''"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="reajusteAvulso"
            dense
            hide-details
            outlined
            label="Reajuste avulso"
            @blur="calculaFormula()"
            :readonly="travaCampos"
            :class="travaCampos ? 'inputCalcEspelho' : ''"
          ></v-text-field>
        </v-col>
      </v-row>
      <div v-if="calcCompleto">
        <div class="d-flex justify-center" style="gap: 32px;">
          <div
            class="d-flex flex-row justify-space-between align-start pa-2"
            style="border: 1px solid #BDBDBD; border-radius: 5px;"
          >
            <div>
              <span class="caption">Trimeste anteriror</span>
              <div
                v-for="(item, index) in valoresTrimestreAnterior"
                :key="index"
                class="d-flex justify-space-between align-start "
              >
                <span class="caption bold mr-3">{{ item.mes }}</span>
                <span class="caption bold">{{ item.valor }}</span>
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-row justify-space-between align-start pa-2"
            style="border: 1px solid #BDBDBD; border-radius: 5px;"
          >
            <div>
              <span class="caption">Últimos seis meses</span>
              <div
                v-for="(item, index) in valoresSeis"
                :key="index"
                class="d-flex justify-space-between align-start"
              >
                <span class="caption bold mr-3">{{ item.mes }}</span>
                <span class="caption bold">{{ item.valor }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column mt-3" style="gap:8px;">
          <div>
            Soma trimestre anteriror {{ espelhoCalc[0] }} / 3 =
            {{ espelhoCalc[1] }}
          </div>
          <div>
            Soma seis últimos meses {{ espelhoCalc[2] }} / 6 =
            {{ espelhoCalc[3] }}
          </div>
          <div>
            Soma dos dois valores dividido por 2 =
            {{ espelhoCalc[4] }}
          </div>
          <div>
            Indice aplicado =
            <strong class="yellow--text text--darken-4">{{
              espelhoCalc[5]
            }}</strong>
          </div>
          <div>
            Reajuste avulso =
            <strong class="blue--text text--darken-4">{{
              espelhoCalc[6]
            }}</strong>
          </div>
          <div>
            Meta =
            <strong class="green--text text--darken-4">{{
              espelhoCalc[7]
            }}</strong>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-space-around align-center"
        style="width: 15vw;"
      >
        <v-btn
          class="error mt-4"
          x-small
          :disabled="!travaCampos"
          @click="dialogRemoveMeta = true"
          >Remover Meta</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          class="success mt-4"
          x-small
          :disabled="travaCampos"
          @click="dialogSalvarMeta = true"
          >Salvar Meta</v-btn
        >
      </div>
    </div>

    <!-- Meta -->
    <div class="d-flex flex-column align-center" style="width: 18vw;">
      <div
        class="d-flex flex-row justify-space-between pa-1"
        style="border: 1px solid #BDBDBD; width: 360px; background-color: #1976D2; color:white;"
      >
        <span>Mês</span>
        <span>Meta</span>
        <span>%</span>
      </div>
      <div v-for="(item, index) in resultTrimestre" :key="index">
        <div
          class="d-flex flex-row justify-space-between align-center pa-1"
          v-for="(itens, i) in metas.filter(m => m.trimestre === index + 1)"
          :key="i"
          style="border: 1px solid #BDBDBD; width: 360px;"
        >
          <span class="caption" style="width: 70px;">{{ itens.mes }}</span>
          <span
            v-if="itens.metaCurr > 0"
            class="caption"
            style="display: flex; justify-content: end;align-items: end;width: 100px;"
            >{{ itens.meta }}</span
          >
          <span
            v-else
            class="caption"
            style="display: flex; justify-content: end;align-items: end;width: 100px;"
            >-</span
          >
          <span
            v-if="!isNaN(itens.percent)"
            class="caption"
            style="display: flex; justify-content: end;align-items: end;width: 60px;"
            >{{ itens.percent }} %</span
          >
          <span
            v-else
            class="caption"
            style="display: flex; justify-content: end;align-items: end;width: 60px;"
            >-</span
          >
        </div>
        <div
          v-if="item.valorCurr > 0"
          class="d-flex flex-row justify-space-between pa-1"
          style="border: 1px solid #BDBDBD; width: 360px; background-color: #BBDEFB;"
        >
          <span>{{ item.title }}</span>
          <span>{{ item.valor }}</span>
          <span v-if="!isNaN(item.percent)">{{ item.percent }} %</span>
          <span v-else>-</span>
        </div>
        <div
          v-else
          class="d-flex flex-row justify-space-between pa-1"
          style="border: 1px solid #BDBDBD; width: 360px; background-color: #BBDEFB;"
        >
          <span>{{ item.title }}</span>
          <span>-</span>
          <span>-</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { stringToBrMoney, stringToFloat } from "@/utils/stringParse";
import { mapState } from "vuex";
import { subMonths, add, sub } from "date-fns";
import axios from "axios";
export default {
  name: "calcAssistentes",
  computed: {
    ...mapState({
      tabFaturamento: state => state.metasOpimed.tabFaturamento,
      headersFaturamento: state => state.metasOpimed.headersFaturamento,
      indiceReajuste: state => state.metasOpimed.indiceReajuste,
      ajustes: state => state.metasOpimed.ajustes,
      tabMetas: state => state.metasOpimed.tabMetas,
      trimestre: state => state.metasOpimed.trimestre,
      dadosCalculo: state => state.metasOpimed.dadosCalculo,
      assistentes: state => state.metasOpimed.assistentes,
      anoEscolhido: state => state.metasOpimed.anoEscolhido,
    }),
  },
  data: () => {
    return {
      dialogRemoveMeta: false,
      dialogSalvarMeta: false,
      travaCampos: false,
      dialogAguarde: false,
      indiceBanco: [],
      reajusteAvulso: null,
      indRejuste: null,
      title: "Unidade de negócio",
      espelhoCalc: [],
      calcCompleto: false,
      valoresSeis: [],
      valoresTrimestreAnterior: [],
      somaSeis: 0,
      somaTrimestreAnterior: 0,
      metas: [
        { mes: "Janeiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Fevereiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Março", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Abril", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Maio", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Junho", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Julho", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Agosto", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Setembro", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Outubro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Novembro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Dezembro", meta: 0, percent: 0, trimestre: 4 },
      ],
      resultTrimestre: [
        { title: "1 Trimestre", valor: 0, percent: 0, trimestre: 1 },
        { title: "2 Trimestre", valor: 0, percent: 0, trimestre: 2 },
        { title: "3 Trimestre", valor: 0, percent: 0, trimestre: 3 },
        { title: "4 Trimestre", valor: 0, percent: 0, trimestre: 4 },
      ],
    };
  },
  methods: {
    async removeMeta() {
      const dadosRemove = {
        D_E_L_E_T_: "*",
        R_E_C_D_E_L_: this.indiceBanco[0].R_E_C_N_O_,
        R_E_C_N_O_: this.indiceBanco[0].R_E_C_N_O_,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/metas`;

      await axios
        .put(url, dadosRemove)
        .then(res => {
          this.$toast.success(res.data, {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.dialogRemoveMeta = false;
          this.buscaIndice();
        })
        .catch(() => {
          this.$toast.error("Salvo com sucesso!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    sumField(key) {
      if (key === "Mês") return;
      // sum data in give key (property)
      return stringToBrMoney(
        this.tabFaturamento.reduce((a, b) => a + (b[`C-${key}`] * 1 || 0), 0),
      );
    },
    mediaField(key) {
      if (key === "Mês") return;
      // sum data in give key (property)
      return stringToBrMoney(
        stringToFloat(
          this.tabFaturamento.reduce(
            (a, b) => a + (b[`C-${key}`] / 12 || 0),
            0,
          ),
        ),
      );
    },
    calculaFormula() {
      this.calcCompleto = false;
      this.valoresSeis = [];
      this.valoresTrimestreAnterior = [];
      this.somaSeis = 0;
      this.somaTrimestreAnterior = 0;
      this.espelhoCalc = [];

      const dataTrimestre = this.trimestre.map(e => {
        return {
          inicio: `${this.anoEscolhido}-${e.value.split("_")[0]}`,
          fim: `${this.anoEscolhido}-${e.value.split("_")[1]}`,
        };
      });
      const dataInicioTrimestre = dataTrimestre[0].inicio.split("-");

      const subtraiSete = subMonths(
        new Date(
          dataInicioTrimestre[0],
          dataInicioTrimestre[1],
          dataInicioTrimestre[2],
        ),
        7,
      );

      const meses = [
        { id: "01", title: "Janeiro", value: "00" },
        { id: "02", title: "Fevereiro", value: "01" },
        { id: "03", title: "Março", value: "02" },
        { id: "04", title: "Abril", value: "03" },
        { id: "05", title: "Maio", value: "04" },
        { id: "06", title: "Junho", value: "05" },
        { id: "07", title: "Julho", value: "06" },
        { id: "08", title: "Agosto", value: "07" },
        { id: "09", title: "Setembro", value: "08" },
        { id: "10", title: "Outubro", value: "09" },
        { id: "11", title: "Novembro", value: "10" },
        { id: "12", title: "Dezembro", value: "11" },
      ];

      for (let cont = 0; cont <= 5; cont++) {
        const mes = add(subtraiSete, { months: cont }).getMonth();
        const ano = add(subtraiSete, { months: cont }).getFullYear();
        this.somaSeis += this.tabFaturamento.find(e => Number(e.valor) === mes)[
          `C-${ano}`
        ][0]
          ? this.tabFaturamento.find(e => Number(e.valor) === mes)[
              `C-${ano}`
            ][0]
          : 0;
        this.valoresSeis.push({
          ano,
          mes: meses.find(e => Number(e.value) === mes)["title"],
          valor: stringToBrMoney(
            this.tabFaturamento.find(e => Number(e.valor) === mes)[
              `C-${ano}`
            ][0],
          ),
        });
      }

      const anoAnterior = sub(new Date(this.anoEscolhido, 0, 1), { years: 1 });

      let inicioTrimestreAnterior = new Date(
        anoAnterior.getFullYear(),
        dataInicioTrimestre[1],
        dataInicioTrimestre[2],
      );

      for (let cont = 0; cont <= 2; cont++) {
        const mes = add(inicioTrimestreAnterior, { months: cont }).getMonth();
        const ano = add(inicioTrimestreAnterior, {
          months: cont,
        }).getFullYear();
        this.somaTrimestreAnterior += this.tabFaturamento.find(
          e => Number(e.valor) === mes,
        )[`C-${ano}`][0]
          ? this.tabFaturamento.find(e => Number(e.valor) === mes)[
              `C-${ano}`
            ][0]
          : 0;
        this.valoresTrimestreAnterior.push({
          ano,
          mes: meses.find(e => Number(e.value) === mes)["title"],
          valor: this.tabFaturamento.find(e => Number(e.valor) === mes)[
            `C-${ano}`
          ][0]
            ? stringToBrMoney(
                this.tabFaturamento.find(e => Number(e.valor) === mes)[
                  `C-${ano}`
                ][0],
              )
            : "-",
        });
      }

      if (!this.indRejuste) return;

      let indiceReaj = parseFloat(this.indRejuste.replace(",", "."));

      let reajusteAv = this.reajusteAvulso
        ? parseFloat(this.reajusteAvulso.replace(".", "").replace(",", "."))
        : 0;

      this.espelhoCalc.push(stringToBrMoney(this.somaTrimestreAnterior));
      this.espelhoCalc.push(stringToBrMoney(this.somaTrimestreAnterior / 3));
      this.espelhoCalc.push(stringToBrMoney(this.somaSeis));
      this.espelhoCalc.push(stringToBrMoney(this.somaSeis / 6));
      const soma1 = (this.somaTrimestreAnterior / 3 + this.somaSeis / 6) / 2;
      this.espelhoCalc.push(stringToBrMoney(soma1));
      const soma2 =
        ((this.somaTrimestreAnterior / 3 + this.somaSeis / 6) / 2) * indiceReaj;
      this.espelhoCalc.push(stringToBrMoney(soma2));
      this.espelhoCalc.push(stringToBrMoney(reajusteAv));
      this.espelhoCalc.push(stringToBrMoney(soma2 + reajusteAv));

      this.calcCompleto = true;
    },
    async buscaIndice() {
      //Consultar a tabela de espelho de calculo
      this.metas = [
        { mes: "Janeiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Fevereiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Março", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Abril", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Maio", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Junho", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Julho", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Agosto", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Setembro", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Outubro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Novembro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Dezembro", meta: 0, percent: 0, trimestre: 4 },
      ];
      this.resultTrimestre = [
        { title: "1 Trimestre", valor: 0, percent: 0, trimestre: 1 },
        { title: "2 Trimestre", valor: 0, percent: 0, trimestre: 2 },
        { title: "3 Trimestre", valor: 0, percent: 0, trimestre: 3 },
        { title: "4 Trimestre", valor: 0, percent: 0, trimestre: 4 },
      ];
      this.indiceBanco = [];
      this.travaCampos = false;
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      let anoTrimestre = "";

      if (Number(this.trimestre[0].value.split("-")[0]) === 0) {
        anoTrimestre = `${this.anoEscolhido}01`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 3) {
        anoTrimestre = `${this.anoEscolhido}02`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 6) {
        anoTrimestre = `${this.anoEscolhido}03`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 9) {
        anoTrimestre = `${this.anoEscolhido}04`;
      }

      let tipo = "PP";
      let loja = this.dadosCalculo[0].itens[0].ZXD_LOJA;

      if (this.dadosCalculo[0].itens[0].ZXD_VEND.substr(0, 2) === "PF") {
        tipo = "PF";
        loja = this.dadosCalculo[0].itens[0].ZXD_VEND;
      }

      let dados = {
        FIELDS: "*",
        WHERE: `ZXE_TPCALC='${tipo}' AND ZXE_ANOTRI='${anoTrimestre}' AND ZXE_LOJA='${loja}'`,
        TABELA: "ZXE",
      };

      const indiceBanco = await axios.post(url, dados);

      if (indiceBanco.data.length > 0) {
        this.indiceBanco = indiceBanco.data;
        this.indRejuste = indiceBanco.data[0].ZXE_INDICE.toString().replace(
          ".",
          ",",
        );
        this.reajusteAvulso = indiceBanco.data[0].ZXE_VLREAJ.toLocaleString(
          "pt-BR",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        );
        this.calculaFormula();
        this.travaCampos = true;
      }

      dados = {
        FIELDS: "ZXE_ANOTRI,ZXE_INDICE,ZXE_VLREAJ,ZXE_META",
        WHERE: `ZXE_TPCALC='${tipo}' AND LEFT(ZXE_ANOTRI,4)='${this.anoEscolhido}' AND ZXE_LOJA='${loja}'`,
        TABELA: "ZXE",
      };

      const metasBanco = await axios.post(url, dados);

      if (metasBanco.data.length > 0) {
        metasBanco.data.map(e => {
          if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 1) {
            this.metas
              .filter(m => m.trimestre === 1)
              .map(m => {
                m.meta = stringToBrMoney(e.ZXE_META);
                m.metaCurr = Number(e.ZXE_META.toFixed(2));
              });
            this.resultTrimestre
              .filter(t => t.trimestre === 1)
              .map(t => {
                t.valor = stringToBrMoney(e.ZXE_META * 3);
                t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
              });
          }
          if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 2) {
            this.metas
              .filter(m => m.trimestre === 2)
              .map(m => {
                m.meta = stringToBrMoney(e.ZXE_META);
                m.metaCurr = Number(e.ZXE_META.toFixed(2));
              });
            this.resultTrimestre
              .filter(t => t.trimestre === 2)
              .map(t => {
                t.valor = stringToBrMoney(e.ZXE_META * 3);
                t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
              });
          }
          if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 3) {
            this.metas
              .filter(m => m.trimestre === 3)
              .map(m => {
                m.meta = stringToBrMoney(e.ZXE_META);
                m.metaCurr = Number(e.ZXE_META.toFixed(2));
              });
            this.resultTrimestre
              .filter(t => t.trimestre === 3)
              .map(t => {
                t.valor = stringToBrMoney(e.ZXE_META * 3);
                t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
              });
          }
          if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 4) {
            this.metas
              .filter(m => m.trimestre === 4)
              .map(m => {
                m.meta = stringToBrMoney(e.ZXE_META);
                m.metaCurr = Number(e.ZXE_META.toFixed(2));
              });
            this.resultTrimestre
              .filter(t => t.trimestre === 4)
              .map(t => {
                t.valor = stringToBrMoney(e.ZXE_META * 3);
                t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
              });
          }
        });
      }

      const anoAnterior = sub(new Date(this.anoEscolhido, 0, 1), { years: 1 });

      let inicioAnoAnterior = new Date(anoAnterior.getFullYear(), 0, 1);

      let trimestreCont = 1;
      let contMes = 0;
      let valorTrimAnterior = 0;

      for (let cont = 0; cont <= 12; cont++) {
        const mes = add(inicioAnoAnterior, { months: cont }).getMonth();
        const ano = add(inicioAnoAnterior, {
          months: cont,
        }).getFullYear();

        if (contMes === 3 && trimestreCont === 1) {
          const valor = this.resultTrimestre.filter(
            t => t.trimestre === trimestreCont,
          )[0].valorCurr;

          if (valor > 0) {
            this.resultTrimestre
              .filter(t => t.trimestre === trimestreCont)
              .map(
                t =>
                  (t.percent = (
                    (valor * 100) / valorTrimAnterior -
                    100
                  ).toFixed(2)),
              );
          }
          contMes = 0;
          valorTrimAnterior = 0;
          trimestreCont++;
        }

        if (contMes === 3 && trimestreCont === 2) {
          const valor = this.resultTrimestre.filter(
            t => t.trimestre === trimestreCont,
          )[0].valorCurr;

          if (valor > 0) {
            this.resultTrimestre
              .filter(t => t.trimestre === trimestreCont)
              .map(
                t =>
                  (t.percent = (
                    (valor * 100) / valorTrimAnterior -
                    100
                  ).toFixed(2)),
              );
          }
          contMes = 0;
          valorTrimAnterior = 0;
          trimestreCont++;
        }

        if (contMes === 3 && trimestreCont === 3) {
          const valor = this.resultTrimestre.filter(
            t => t.trimestre === trimestreCont,
          )[0].valorCurr;

          if (valor > 0) {
            this.resultTrimestre
              .filter(t => t.trimestre === trimestreCont)
              .map(
                t =>
                  (t.percent = (
                    (valor * 100) / valorTrimAnterior -
                    100
                  ).toFixed(2)),
              );
          }
          contMes = 0;
          valorTrimAnterior = 0;
          trimestreCont++;
        }

        if (contMes === 3 && trimestreCont === 4) {
          const valor = this.resultTrimestre.filter(
            t => t.trimestre === trimestreCont,
          )[0].valorCurr;

          if (valor > 0) {
            this.resultTrimestre
              .filter(t => t.trimestre === trimestreCont)
              .map(
                t =>
                  (t.percent = (
                    (valor * 100) / valorTrimAnterior -
                    100
                  ).toFixed(2)),
              );
          }
          contMes = 0;
          valorTrimAnterior = 0;
          trimestreCont++;
        }

        if (cont <= 11) {
          const valor = this.metas.filter(m => m.trimestre === trimestreCont)[0]
            .metaCurr;

          if (valor > 0) {
            this.metas.filter(m => m.trimestre === trimestreCont)[
              contMes
            ].percent = Number(
              (
                (valor * 100) /
                  this.tabFaturamento.find(e => Number(e.valor) === mes)[
                    `C-${ano}`
                  ][0] -
                100
              ).toFixed(2),
            );

            valorTrimAnterior += this.tabFaturamento.find(
              e => Number(e.valor) === mes,
            )[`C-${ano}`][0];
          }
        }

        contMes++;
      }
    },
    async salvaMeta() {
      if (!this.espelhoCalc[7]) return;
      let anoTrimestre = "";

      if (Number(this.trimestre[0].value.split("-")[0]) === 0) {
        anoTrimestre = `${this.anoEscolhido}01`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 3) {
        anoTrimestre = `${this.anoEscolhido}02`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 6) {
        anoTrimestre = `${this.anoEscolhido}03`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 9) {
        anoTrimestre = `${this.anoEscolhido}04`;
      }

      let tipo = "PP";
      let loja = this.dadosCalculo[0].itens[0].ZXD_LOJA;

      if (this.dadosCalculo[0].itens[0].ZXD_VEND.substr(0, 2) === "PF") {
        tipo = "PF";
        loja = this.dadosCalculo[0].itens[0].ZXD_VEND;
      }

      const dadosInsert = {
        ZXE_TIPO: this.dadosCalculo
          .map(e => e.itens)
          .map(e => e[0].ZXD_TIPO)[0],
        ZXE_TPCALC: tipo,
        ZXE_ANOTRI: anoTrimestre,
        ZXE_INDICE: parseFloat(this.indRejuste.replace(",", ".")),
        ZXE_VLREAJ: this.reajusteAvulso
          ? parseFloat(this.reajusteAvulso.replace(".", "").replace(",", "."))
          : 0,
        ZXE_LOJA: loja,
        ZXE_META: parseFloat(
          this.espelhoCalc[7]
            .replace("R$", "")
            .replaceAll(".", "")
            .replace(",", "."),
        ),
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/metas`;

      await axios
        .post(url, dadosInsert)
        .then(res => {
          this.$toast.success(res.data, {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.dialogSalvarMeta = false;
          this.buscaIndice();
        })
        .catch(err => {
          console.log(err.response);
          this.$toast.error("Salvo com sucesso!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
  beforeMount() {
    this.buscaIndice();
  },
};
</script>
<style>
.v-data-table.tbmetasOpimed tbody tr td {
  font-size: 0.8rem !important;
  height: 25px !important;
  min-width: 130px;
}

.v-input.inputCalcEspelho {
  background-color: grey !important;
}
.v-input.inputCalcEspelho .v-label {
  margin: 5px;
  color: white;
}
.v-input.inputCalcEspelho input {
  color: white;
}

/* .v-data-table.tbmetasOpimed thead tr th {
  background-color: blue !important;
}
.v-data-table.tbmetasOpimed thead tr th span {
  color: white;
} */
</style>
